import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';
import { AgentProduct } from '~/configs/AgentProduct';
import { FrLogic } from '~/modules/FrLogic/v1/FrLogic';
import { createInstrumentStore } from '~/pages/strategy_center_admin/_store/createInstrumentStore';
import { createMeStore } from '~/pages/strategy_center_admin/_store/createMeStore';
import { createModalStore } from '~/pages/strategy_center_admin/_store/createModalStore';
import { createMonitorStore } from '~/pages/strategy_center_admin/_store/createMonitorStore';
import { createStrategistStore } from '~/pages/strategy_center_admin/_store/createStrategistStore';
import { createStrategyOverviewStore } from '~/pages/strategy_center_admin/_store/createStrategyOverviewStore';
import { createStrategyStore } from '~/pages/strategy_center_admin/_store/createStrategyStore';
import { createEventLogStore } from '~/pages/strategy_center_admin/_store/createEventLogStore';
import { createUsersStore } from '~/pages/strategy_center_admin/_store/createUsersStore';
import { createAuditLogStore } from '~/pages/strategy_center_admin/_store/createAuditLogStore';
import { createSettlementStore } from '~/pages/strategy_center_admin/_store/createSettlementStore';
import { createRollStore } from '~/pages/strategy_center_admin/_store/createRollStore';
import { createOrderPanelStore } from '~/pages/strategy_center_admin/_store/createOrderPanelStore';
export const pageStore = proxy({
    drawer1: FrLogic.drawer({ initialProps: { position: 'left' } }),
    me: createMeStore(),
    modalStore: createModalStore(),
    /** 如果你一層 modal 不夠，須要跳出第二層的話 */
    modal2Store: createModalStore(),
    strategistsStore: createStrategistStore(),
    strategyOverviewStore: createStrategyOverviewStore(),
    instrumentsStore: createInstrumentStore(),
    strategyStore: createStrategyStore(),
    monitorStore: createMonitorStore(),
    usersStore: createUsersStore(),
    eventLog: createEventLogStore(),
    auditLog: createAuditLogStore(),
    settlementStore: createSettlementStore(),
    rollStore: createRollStore(),
    orderPanel: createOrderPanelStore(),
});
devtools(pageStore, { name: AgentProduct['futuresai@strategy_center_admin'] });
