import { Fragment } from 'react';
import useSWR from 'swr';
import { useRefreshMeLoginState } from '~/pages/strategy_center_admin/_hooks/useRefreshMeLoginState';
import { pageStore } from '~/pages/strategy_center_admin/pageStore';
async function fetcher() {
    await pageStore.instrumentsStore.loadInstruments();
    await pageStore.strategistsStore.loadStrategists();
}
export function DataProvider(props) {
    const state = useSWR(fetcher);
    useRefreshMeLoginState({
        refreshInterval: 1000 * 60 * 1,
        // refreshInterval: 1000,
    });
    if (state.isLoading)
        return 'Loading...';
    return (<Fragment>
      <Fragment>{props.children}</Fragment>
    </Fragment>);
}
