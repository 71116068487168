import { dayjs } from '@futures.tw/dayjs';
import { proxy } from 'valtio';
import { apirc } from '~/configs/apirc';
export function createRollStore() {
    const state = {
        rollRules: [],
        async reloadRollRules() {
            apirc.strategy
                .http('get', '/api/contractRollRules')
                .request({
                'datetime[gte]': store.filter.datetimeGte.startOf('day').format(),
                'datetime[lte]': store.filter.datetimeLte.endOf('day').format(),
                symbolRoot: store.filter.symbolRoot,
                previousContractMonth: store.filter.contractMonth,
            })
                .then(res => {
                store.rollRules = res.data || [];
            });
        },
        filter: {
            symbolRoot: '',
            contractMonth: '',
            datetimeGte: dayjs().subtract(6, 'month'),
            datetimeLte: dayjs().add(6, 'month'),
        },
    };
    const store = proxy({
        ...state,
    });
    return store;
}
